import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

/**
 * Provides a form input component defined in a Drupal way.
 *
 * @param {Object} props
 * @return {null|*}
 * @constructor
 */
export function Input (props) {
  const { name, value, onChange, config } = props

  switch (config['type']) {
    case 'radios':
      return Object.keys(config['options']).map(key => (
        <div key={key} className="form-check">
          <input
            id={'radio-' + name + '-' + key}
            name={name}
            type="radio"
            value={key}
            checked={key === value}
            onChange={onChange}
            className="form-check-input"
            required={!!config['required']}
          />
          <label
            className="form-check-label"
            htmlFor={'radio-' + name + '-' + key}
          >
            {config['options'][key]}
          </label>
        </div>
      ))

    default:
      return (
        <Fragment>
          {!!config.label && (
            <label htmlFor={name} className="form-label">
              {config.label}
            </label>
          )}
          <input
            autoComplete="off"
            className="form-control"
            {...config}
            name={name}
            value={value}
            onChange={onChange}
          />
        </Fragment>
      )
  }
}

/**
 * Component properties.
 *
 * @type {{}}
 */
Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  config: PropTypes.object
}
