import React, {useCallback, useMemo} from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {ProgressBar, Tab, Table, Tabs} from 'react-bootstrap'

import { DataStatus, LaTex } from 'ps/components'
import { BUNDLES } from 'ps/constants'
import { useBundle, useLoadTempEffect, useReset, useStudent } from 'ps/hooks'
import { actions } from 'ps/store'

import { StudentBackLink } from './StudentBackLink'

/**
 * Renders a single course topic.
 *
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
export function StudentCourseTopic (props) {
  const { code } = useStudent()
  const { id } = useParams()

  useLoadTempEffect(BUNDLES.GET_STUDENT_COURSE_TOPIC, null, { code, id })

  return (
    <div className="col">
      <DataStatus bundle={BUNDLES.GET_STUDENT_COURSE_TOPIC}>
        <Content/>
      </DataStatus>
    </div>
  )
}

/**
 * Renders page content.
 *
 * @return {*}
 * @constructor
 */
function Content () {
  const dispatch = useDispatch()
  const { code } = useStudent()
  const { data } = useBundle(BUNDLES.GET_STUDENT_COURSE_TOPIC)
  const { id, course, title, body, documents, video } = data

  const defaultKey = useMemo(function () {
    const { body, documents, video } = data
    if (body) {
      return 'body'
    }
    if (video) {
      return 'video'
    }
    if (documents) {
      return 'documents'
    }
    return 'log'
  }, [data])

  const onLogTabEnter = useCallback(function () {
    dispatch(actions[BUNDLES.GET_STUDENT_COURSE_TOPIC_LOG].load(null, { code, id }))
  }, [code, id])

  useReset(BUNDLES.GET_STUDENT_COURSE_TOPIC_LOG)

  return (
    <div className="card mb-4">
      <div className="card-header fs-5">
        <StudentBackLink path={'..?course=' + course.id} permanent={true}/>
        {course.title}
      </div>
      <div className="card-body">
        <h2 className="card-title mb-3 fw-normal">{title}</h2>
        <Tabs defaultActiveKey={defaultKey}>
          {!!body && (
            <Tab eventKey="body" title="Теория">
              <LaTex content={body} className="mt-3 course-topic--body"/>
            </Tab>
          )}
          {!!video && video.uri && (
            <Tab eventKey="video" title="Видео">
              <div className="ratio ratio-16x9 mt-3">
                <iframe src={video.uri} className="border-0" allowFullScreen={true}/>
              </div>
            </Tab>
          )}
          {!!documents && (
            <Tab eventKey="documents" title="Файлы">
              <div className="mt-3">
                {documents.map(document => {
                  const { id, title, url } = document
                  return (
                    <div key={id} className="mt-2">
                      <a href={url} target="_blank">{title}</a>
                    </div>
                  )
                })}
              </div>
            </Tab>
          )}
          <Tab eventKey="log" title="Журнал" onEnter={onLogTabEnter}>
            <div className="mt-3">
              <DataStatus bundle={BUNDLES.GET_STUDENT_COURSE_TOPIC_LOG}>
                <StudyLog/>
              </DataStatus>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

function StudyLog () {
  const { data: { score } } = useBundle(BUNDLES.GET_STUDENT_COURSE_TOPIC)
  const { data: { progress, log } } = useBundle(BUNDLES.GET_STUDENT_COURSE_TOPIC_LOG)

  const studyPct = Math.min(100, Math.round(100 * (progress || 0) / (score || 1)))
  const progressVariant = studyPct < 33 ? 'danger' : (studyPct < 66 ? 'warning' : 'success')

  return (
      <>
        <ProgressBar
          label={`${progress} из ${score}`}
          now={progress}
          max={score}
          variant={progressVariant}
        />
        <Table striped>
          <thead>
          <tr>
            <th>Дата</th>
            <th>Баллы</th>
            <th>Примечание</th>
          </tr>
          </thead>
          <tbody>
          {log.map(item => {
            const { id, date, score, note } = item
            return (
              <tr key={id}>
                <td>{date}</td>
                <td>{score}</td>
                <td>{note}</td>
              </tr>
            )
          })}
          </tbody>
        </Table>
      </>
  )
}
