import {
  FORM_INITIAL_STATE,
  LOAD_INITIAL_STATE,
  FORM_BUNDLES,
  LOAD_BUNDLES
} from 'ps/constants'

/**
 * Default values of single data pieces of multi-keyed bundles.
 */
export const defaults = {
}

// Add simple load actions.
Object.keys(LOAD_BUNDLES).forEach(bundle => {
  defaults[bundle] = { ...LOAD_INITIAL_STATE }
})

// Add simple form actions.
FORM_BUNDLES.forEach(bundle => {
  defaults[bundle] = { ...FORM_INITIAL_STATE }
})
