import React from 'react'

import { DataStatus } from 'ps/components'
import { BUNDLES } from 'ps/constants'
import { useBundle, useLoadPermEffect, useStudent } from 'ps/hooks'

import { StudentBackLink } from './StudentBackLink'

/**
 * Renders student's announcements page.
 *
 * @return {JSX.Element}
 * @constructor
 */
export function StudentAnnouncements () {
  const { code } = useStudent()

  useLoadPermEffect(BUNDLES.GET_STUDENT_ANNOUNCEMENTS, null, { code })

  return (
    <div className="col">
      <div className="card">
        <div className="card-header fs-5">
          <StudentBackLink/>
          Мои объявления
        </div>
        <div className="card-body">
          <DataStatus bundle={BUNDLES.GET_STUDENT_ANNOUNCEMENTS}>
            <Content/>
          </DataStatus>
        </div>
      </div>
    </div>
  )
}

/**
 * Renders a page content.
 *
 * @return {*}
 * @constructor
 */
function Content () {
  const { data } = useBundle(BUNDLES.GET_STUDENT_ANNOUNCEMENTS)
  const { groups } = data

  if (groups) {
    return groups.map(group => (
      <div key={group['id']} className="mb-3">
        <h3>{group['name']}</h3>
        <div className="row g-3">
          {group['images'].map(image => {
            const { fid, alt, title, url } = image
            return (
              <div key={fid} className="col-12 col-xl-6">
                <a href={url} target="_blank" download={true}>
                  <img src={url} alt={alt} title={title} className="img-fluid img-thumbnail"/>
                </a>
              </div>
            )
          })}
        </div>
      </div>
    ))
  }

  return null
}
