import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { useBundle } from 'ps/hooks'

import { Error } from './Error'
import { Loading } from './Loading'
import { Spinner } from './Spinner'

/**
 * Renders data status block.
 *
 * @param {Object} props
 * @return {*}
 * @constructor
 */
export function DataStatus (props) {
  const { bundle, bundleKey, inline, append, loader, children } = props
  const { error, loading, data } = useBundle(bundle, bundleKey)
  const hasData = !!data

  return (
    <Fragment>
      {append && hasData && children}
      {inline ? (
        <Spinner show={loading && !loader}/>
      ) : (
        <Fragment>
          <Loading loading={loading && !loader} className="my-3 text-center"/>
          <Error error={error} className="shadow"/>
        </Fragment>
      )}
      {!append && !loading && !error && hasData && children}
      {loader}
    </Fragment>
  )
}

/**
 * Component properties.
 *
 * @type {Object}
 */
DataStatus.propTypes = {
  bundle: PropTypes.string.isRequired,
  bundleKey: PropTypes.any,
  inline: PropTypes.bool,
  append: PropTypes.bool,
  loader: PropTypes.any
}

/**
 * Component props default values.
 *
 * @type {{}}
 */
DataStatus.defaultProps = {
  bundleKey: null,
  inline: false,
  append: false,
  loader: null
}
