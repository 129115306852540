/**
 * @file
 * Various utilities.
 */

import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

import { SITE_NAME } from 'ps/constants'

/**
 * Sets page title.
 *
 * @param {String} title
 *   Page title to set.
 */
export function setPageTitle (title = null) {
  if (title) {
    document.title = title + ' | ' + SITE_NAME
  }
  else {
    document.title = SITE_NAME
  }
}

/**
 * Returns window/container's scrollTop value.
 *
 * @param {HTMLElement} container
 *   (optional) Scrollable container to get scrollTop of. If not set,
 *   document's scrollTop is returned.
 *
 * @return {number}
 */
export function getScrollTop (container = null) {
  if (container) {
    return container.scrollTop || 0
  }
  else {
    return window.scrollY || window.pageYOffset
      || document.documentElement && document.documentElement.scrollTop || 0
  }
}

/**
 * Sets CSRF token.
 *
 * @param {String} token
 */
export function setCsrfToken (token) {
  window.localStorage.setItem('csrfToken', token || '')
}

/**
 * Returns saved CSRF token.
 *
 * @return {string}
 */
export function getCsrfToken () {
  return window.localStorage.getItem('csrfToken')
}

/**
 * Formats a date.
 *
 * @param {Date|int} date
 *   The date to format.
 * @param {string} dateFormat
 *   The format to use.
 *
 * @return {string}
 *   Formatted date string.
 */
export function formatDate (date, dateFormat) {
  return format(date, dateFormat, {
    locale: ru,
    weekStartsOn: 1
  })
}

/**
 * Formats a date using a "Y-m-d" format.
 *
 * @param date
 *   The date to format.
 *
 * @return {string}
 *   Formatted date string.
 */
export function formatDay (date) {
  return formatDate(date, 'yyyy-MM-dd')
}


/**
 * Formats a date using a "Y-m" format.
 *
 * @param date
 *   The date to format.
 *
 * @return {string}
 *   Formatted date string.
 */
export function formatMonth (date) {
  return formatDate(date, 'yyyy-MM')
}

/**
 * Parses URL query and returns it as an object.
 *
 * @param {string} query
 *   (optional) URL query to parse. Defaults to window.location.search.
 * @param {[]} exclude
 *   (optional) Array of keys to exclude.
 *
 * @return {{}}
 *
 * @see buildQuery()
 */
export function parseQuery (query = window.location.search, exclude = []) {
  if (query.indexOf('?') === 0) {
    query = query.slice(1)
  }

  const params = query.split('&').filter((item) => item)

  let result = {}, c, key, value
  params.forEach(param => {
    c = param.split('=')
    key = decodeURIComponent(c[0])
    if (exclude.indexOf(key) === -1) {
      value = c[1] ? decodeURIComponent(c[1]) : true
      result[key] = value
    }
  })

  return result
}
