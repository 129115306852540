import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faImage } from '@fortawesome/pro-solid-svg-icons'

import { DataStatus, MonthDataLoader } from 'ps/components'
import { BUNDLES } from 'ps/constants'
import { useBundle, useLoadTempEffect, useStudent } from 'ps/hooks'

import { StudentBackLink } from './StudentBackLink'

/**
 * Renders a student media page.
 *
 * @return {JSX.Element}
 * @constructor
 */
export function StudentMedia () {
  const { code } = useStudent()

  useLoadTempEffect(BUNDLES.GET_STUDENT_MEDIA, null, { code })

  return (
    <div className="col">
      <div className="card">
        <div className="card-header fs-5">
          <StudentBackLink/>
          Мои фото и видео
        </div>
        <DataStatus
          bundle={BUNDLES.GET_STUDENT_MEDIA}
          append={true}
          loader={(
            <div className="card-body text-center">
              <MonthDataLoader bundle={BUNDLES.GET_STUDENT_MEDIA}/>
            </div>
          )}
        >
          <Content/>
        </DataStatus>
      </div>
    </div>
  )
}

/**
 * Renders a page content.
 *
 * @param {Object} props
 * @return {*}
 * @constructor
 */
function Content (props) {
  const { data } = useBundle(BUNDLES.GET_STUDENT_MEDIA)

  return (
    <div className="list-group list-group-flush">
      {data.map(day => (
        <MediaDay key={day.date} data={day}/>
      ))}
      {data.length === 0 && (
        <div className="my-4 text-muted fs-4 text-center">
          Кажется, в этом разделе пока пусто
        </div>
      )}
    </div>
  )
}

/**
 * Renders a day section.
 *
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
function MediaDay (props) {
  const { data } = props
  const { date_day, date_month, items } = data

  return (
    <div className="list-group-item py-3">
      <div className="hstack gap-3 align-items-start">
        <div className="event-date">
          <span className="event-date-month">{date_month}</span>
          <span className="event-date-day">{date_day}</span>
        </div>
        <div className="me-auto row g-3">
          {items.map(item => (
            <div key={item.id} className="col-auto">
              <MediaItem item={item}/>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
MediaDay.propTypes = {
  data: PropTypes.object.isRequired
}

/**
 * Renders a single media item.
 *
 * @param {Object} props
 * @return {JSX.Element|null}
 * @constructor
 */
function MediaItem (props) {
  const { item } = props
  const { thumbnail, link, bundle, activity } = item

  if (!thumbnail || !link) {
    return null
  }

  const title = activity ? activity['title'] : null
  let icon = null
  switch (bundle) {
    case 'photo':
      icon = faImage
      break

    case 'youtube':
      icon = faVideo
      break
  }

  return (
    <a
      href={link}
      target="_blank"
      className="card bg-light text-white p-1 border-1"
    >
      <img src={thumbnail} className="card-img" alt={title} />
        <div className="card-img-overlay">
          <h6 className="card-title text-shadow">{title}</h6>
          <span
            className="d-block px-2 py-1 rounded bg-light opacity-50 text-danger position-absolute bottom-0 end-0 mb-3 me-3"
            title={bundle === 'youtube' ? 'Видео на YouTube' : 'Фотография'}
          >
            <FontAwesomeIcon
              icon={bundle === 'youtube' ? faVideo : faImage}
            />
          </span>
        </div>
    </a>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
MediaItem.propTypes = {
  item: PropTypes.object.isRequired
}
