import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEarthAmericas } from '@fortawesome/pro-duotone-svg-icons'

import { Spinner } from 'ps/components'
import { BUNDLES, SITE_NAME } from 'ps/constants'
import { useBundle } from 'ps/hooks'


/**
 * Page navbar.
 *
 * @return {null|*}
 * @constructor
 */
export function Navbar () {
  const student = useBundle(BUNDLES.GET_STUDENT)

  return (
    <nav className="navbar navbar-light navbar-shadow bg-white">
      <div className="container">
        <span className="navbar-brand">
          <FontAwesomeIcon icon={faEarthAmericas} size="lg" className="text-primary me-2"/>
          {getTitle(student)}
        </span>
      </div>
    </nav>
  )
}

/**
 * Returns navbar title to bw show in the brand area.
 *
 * @param {Object} data
 *   The student data.
 * @param {boolean} loading
 *   Whether student data is being loaded at the moment.
 *
 * @return {*}
 *   The navbar title.
 */
function getTitle ({ data, loading }) {
  if (loading) {
    return <Spinner show={true}/>
  }
  if (!data) {
    return SITE_NAME
  }
  return data['forename'] + ' ' + data['surname']
}
