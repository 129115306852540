import { ACTION_TYPES } from 'ps/constants'

/**
 * General Redux reducer.
 *
 * @param {Object} state
 *   Redux state.
 * @param {Object} action
 *   Action to handle.
 *
 * @return {Object}
 */
export function baseReducer (state, action) {
  switch (action.type) {

    case ACTION_TYPES.UPDATE:
      if (action.key !== null) {
        return {
          ...state,
          [action.key]: {
            ...state[action.key],
            ...action.update
          }
        }
      }
      else {
        return {
          ...state,
          ...action.update
        }
      }

    case ACTION_TYPES.RESET:
      if (action.key !== null) {
        return {
          ...state,
          [action.key]: {
            ...action.reset
          }
        }
      }
      else {
        return {
          ...action.reset
        }
      }
  }

  return state
}
