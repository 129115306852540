import React from 'react'
import PropTypes from 'prop-types'

/**
 * Common success alert.
 *
 * @param props
 * @return {null|*}
 * @constructor
 */
export function Success (props) {
  const { show, children } = props

  if (show) {
    return (
      <div className="alert alert-success" role="alert">
        {children}
      </div>
    )
  }

  return null
}

/**
 * Component properties.
 *
 * @type {{}}
 */
Success.propTypes = {
  show: PropTypes.bool
}

/**
 * Component props default values.
 *
 * @type {{}}
 */
Success.defaultProps = {
  show: true
}
