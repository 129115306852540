import React from 'react'
import { Link } from 'react-router-dom'
import { faFaceParty } from '@fortawesome/pro-duotone-svg-icons'

import { DataStatus, NoData } from 'ps/components'
import { BUNDLES } from 'ps/constants'
import { useBundle, useLoadTempEffect, useStudent } from 'ps/hooks'

import { StudentBackLink } from './StudentBackLink'

/**
 * Renders student's tasks.
 *
 * @return {JSX.Element}
 * @constructor
 */
export function StudentTasks () {
  const { code } = useStudent()

  useLoadTempEffect(BUNDLES.GET_STUDENT_TASKS, null, { code })

  return (
    <div className="col">
      <div className="card">
        <div className="card-header fs-5">
          <StudentBackLink/>
          Мои задания
        </div>
        <DataStatus bundle={BUNDLES.GET_STUDENT_TASKS}>
          <Content/>
        </DataStatus>
      </div>
    </div>
  )
}

/**
 * Renders page content.
 *
 * @return {*}
 * @constructor
 */
function Content () {
  const { data } = useBundle(BUNDLES.GET_STUDENT_TASKS)

  if (!data || !data.length) {
    return (
      <NoData
        icon={faFaceParty}
        iconColor="success"
        message="Новых заданий нет!"
      />
    )
  }

  return (
    <ul className="list-group list-group-flush fade-in">
      {data.map(task => {
        const { id, title, subject, progress } = task
        return (
          <Link
            key={id}
            to={'' + id}
            className="list-group-item list-group-item-action d-md-flex justify-content-between align-items-center py-3"
          >
              <span className="d-block mb-2 mb-md-0">
                {title}
              </span>
            <span className="small">
              <span className="me-4">
                {subject.title}
              </span>
              {progress || 0}%
            </span>
          </Link>
        )
      })}
    </ul>
  )
}
