import React from 'react'
import PropTypes from 'prop-types'

/**
 * Common info alert.
 *
 * @param props
 * @return {null|*}
 * @constructor
 */
export function Info (props) {
  const { show, children, className } = props

  const classes = ['alert', 'alert-info']
  if (className) {
    classes.push(className)
  }

  if (show) {
    return (
      <div className={classes.join(' ')} role="alert">
        {children}
      </div>
    )
  }

  return null
}

/**
 * Component properties.
 *
 * @type {{}}
 */
Info.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string
}

/**
 * Component props default values.
 *
 * @type {{}}
 */
Info.defaultProps = {
  show: true,
  className: ''
}
