import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

/**
 * Controlled dropdown container.
 */
export class Dropdown extends PureComponent {

  constructor (props) {
    super(props)

    /**
     * Dropdown wrapper reference.
     *
     * @type {{current}}
     * @private
     */
    this._ref = React.createRef()

    // Bind methods.
    this.onDocumentClick = this.onDocumentClick.bind(this)
  }

  /**
   * @inheritDoc
   */
  componentDidUpdate(prevProps) {
    const { isOpen } = this.props

    if (isOpen && !prevProps.isOpen) {
      document.addEventListener('click', this.onDocumentClick)
    }
    else if (!isOpen && prevProps.isOpen) {
      document.removeEventListener('click', this.onDocumentClick)
    }
  }

  /**
   * @inheritDoc
   */
  componentWillUnmount() {
    document.removeEventListener('click', this.onDocumentClick)
  }

  /**
   * Handles document clicks.
   *
   * @param {Object} e
   *   Click event.
   */
  onDocumentClick(e) {
    const { isOpen, onClose } = this.props
    let element = e.target
    let inside = false

    if (isOpen) {
      while (element.parentNode) {
        if (element === this._ref.current) {
          inside = true
          break
        }
        element = element.parentNode
      }

      if (!inside) {
        onClose()
      }
    }
  }

  /**
   * @inheritDoc
   */
  render () {
    const { tag, isOpen, children, className } = this.props
    const props = {
      ref: this._ref,
      className: 'dropdown' + (className ? ' ' + className : '') + (isOpen ? ' show' : '')
    }

    return React.createElement(tag, props, children)
  }

}

/**
 * Component properties.
 *
 * @type {{}}
 */
Dropdown.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  tag: PropTypes.string,
  className: PropTypes.string
}

/**
 * Component props default values.
 *
 * @type {{}}
 */
Dropdown.defaultProps = {
  tag: 'div',
  className: ''
}
