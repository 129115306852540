import { ACTION_TYPES } from 'ps/constants'

/**
 * Action to update arbitrary bundle fields.
 *
 * @param {string} bundle
 *   Store bundle name.
 * @param {Object} update
 *   Partial state to be set.
 * @param {any} key
 *   (optional) Data key inside bundle data.
 *
 * @return {Object}
 */
export function updateBundle (bundle, update, key = null) {
  return {
    type: ACTION_TYPES.UPDATE,
    bundle,
    update,
    key
  }
}

/**
 * Action to reset bundle.
 *
 * @param {string} bundle
 *   Store bundle name.
 * @param {Object} reset
 *   (optional) State to set.
 * @param {any} key
 *   (optional) Data key inside bundle data.
 *
 * @return {Object}
 */
export function resetBundle (bundle, reset = {}, key = null) {
  return {
    type: ACTION_TYPES.RESET,
    bundle,
    reset,
    key
  }
}

/**
 * Action to set arbitrary bundle error.
 *
 * @param {string} bundle
 *   Store bundle name.
 * @param {any} error
 *   Error to set.
 * @param {any} key
 *   (optional) Data key inside bundle data.
 *
 * @return {Object}
 */
export function setBundleError (bundle, error, key = null) {
  return updateBundle(bundle, { error }, key)
}
