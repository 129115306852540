import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * Renders a "no data" message.
 *
 * @param {Object} props
 * @return {*}
 * @constructor
 */
export function NoData (props) {
  const { icon, message, className, iconColor } = props

  const iconProps = {
    icon,
    size: '2x',
  }
  if (iconColor) {
    iconProps.className = 'text-' + iconColor
  }

  return (
    <div className={className}>
      <p className="mb-4 text-center">
        <FontAwesomeIcon {...iconProps}/>
      </p>
      <p className="lead text-center">
        {message}
      </p>
    </div>
  )
}

/**
 * Component properties.
 *
 * @type {Object}
 */
NoData.propTypes = {
  icon: PropTypes.object,
  message: PropTypes.string,
  className: PropTypes.string,
  iconColor: PropTypes.string,
}

/**
 * Component props default values.
 *
 * @type {{}}
 */
NoData.defaultProps = {
  icon: null,
  message: null,
  className: 'my-5',
  iconColor: null,
}
