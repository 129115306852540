import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { format } from 'date-fns'

import { DataStatus } from 'ps/components'
import { BUNDLES, MONTHS, STUDENT_REPORTS } from 'ps/constants'
import { useBundle, useStudent } from 'ps/hooks'
import { actions } from 'ps/store'

import { StudentBackLink } from './StudentBackLink'

/**
 * Renders a student study report.
 *
 * @return {JSX.Element}
 * @constructor
 */
export function StudentReportStudy () {
  const [period, setPeriod] = useState(null)

  return (
    <div className="col">
      <div className="card">
        <div className="card-header fs-5">
          <StudentBackLink permanent={true}/>
          {STUDENT_REPORTS['study']}
        </div>
        <div className="card-body">
          <Filters setPeriod={setPeriod}/>
          {period !== null && (
            <Content period={period}/>
          )}
        </div>
      </div>
    </div>
  )
}

/**
 * Renders a filters form.
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
function Filters (props) {
  const now = new Date()
  const { setPeriod } = props
  const [month, setMonth] = useState(now.getMonth())
  const [year, setYear] = useState(now.getFullYear())
  const { loading } = useBundle(BUNDLES.GET_STUDENT_REPORT_STUDY)

  // Build year options.
  const years = useMemo(function () {
    const years = []
    for (let y = new Date().getFullYear(); y >= 2021; y--) {
      years.push(y)
    }
    return years
  }, [])

  const buildPeriod = useCallback(function (year, month) {
    const date = new Date()
    date.setDate(1)
    date.setMonth(month)
    date.setFullYear(year)
    setPeriod(format(date, 'yyyy-MM'))
  }, [])

  const onSubmit = useCallback(function (e) {
    e.preventDefault()
    buildPeriod(year, month)
  }, [year, month])

  // Build initial period.
  useEffect(function () {
    buildPeriod(year, month)
  }, [])

  return (
    <form
      className="row row-cols-lg-auto g-3 align-items-center mb-3"
      onSubmit={onSubmit}
    >
      <div className="col-12">
        <select
          className="form-select"
          aria-label="Год"
          value={year}
          onChange={e => {
            setYear(1 * e.target.value)
          }}
          disabled={loading}
        >
          {years.map(y => (
            <option key={y} value={y}>{y}</option>
          ))}
        </select>
      </div>
      <div className="col-12">
        <select
          className="form-select"
          aria-label="Месяц"
          value={month}
          onChange={e => {
            setMonth(1 * e.target.value)
          }}
          disabled={loading}
        >
          {MONTHS.map((m, i) => (
            <option key={i} value={i}>{m}</option>
          ))}
        </select>
      </div>
      <div className="col-12">
        <button type="submit" className="btn btn-primary" disabled={loading}>
          Показать
        </button>
      </div>
    </form>
  )
}

Filters.propTypes = {
  setPeriod: PropTypes.func.isRequired
}

/**
 * Renders a page content.
 *
 * @param {Object} props
 * @return {*}
 * @constructor
 */
function Content (props) {
  const dispatch = useDispatch()
  const { code } = useStudent()
  const { period } = props
  const { data } = useBundle(BUNDLES.GET_STUDENT_REPORT_STUDY)

  useEffect(function () {
    dispatch(actions[BUNDLES.GET_STUDENT_REPORT_STUDY].load(null, { code, month: period }))
    return function () {
      dispatch(actions[BUNDLES.GET_STUDENT_REPORT_STUDY].reset())
    }
  }, [code, period])

  return (
    <DataStatus bundle={BUNDLES.GET_STUDENT_REPORT_STUDY}>
      {(data || []).length > 0 ? (
        <table className="table">
          {data.map(subject => (
            <Fragment key={subject['id']}>
              <thead className="table-group-divider">
              <tr>
                <th>{subject['title']}</th>
                <th className="text-end">{subject['score']}</th>
              </tr>
              </thead>
              {subject['courses'].map(course => (
                <tbody key={course['id']}>
                <tr className="table-light">
                  <td>{course['title']}</td>
                  <td className="text-end">{course['score']}</td>
                </tr>
                {course['topics'].map(topic => (
                  <tr key={topic['id']} className="small text-muted">
                    <td className="ps-4">{topic['title']}</td>
                    <td className="text-end">{topic['score']}</td>
                  </tr>
                ))}
                </tbody>
              ))}
            </Fragment>
          ))}
        </table>
      ) : (
        <div className="my-4 text-muted fs-4 text-center">
          Кажется, здесь пока что пусто
        </div>
      )}
    </DataStatus>
  )
}

Content.propTypes = {
  period: PropTypes.string
}
