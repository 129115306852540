import { DATA_HANDLE_METHODS, LOAD_ACTION_TYPES } from 'ps/constants'

import { resetBundle, setBundleError, updateBundle } from './baseActions'

/**
 * Action to load data from the server.
 *
 * @param {string} bundle
 *   Store bundle name.
 * @param {Function} onLoad
 *   Load callback.
 * @param {Object} params
 *   (optional) Additional query parameters, if any.
 * @param {any} key
 *   (optional) Data key inside bundle data.
 *
 * @return {Function}
 */
export function loadData (bundle, onLoad, params = {}, key = null) {
  return async function (dispatch, getState, api) {
    // Start data loading.
    dispatch(updateBundle(bundle, {
      loading: true,
      loaded: false,
      error: null
    }, key))

    // Do load data.
    try {
      const response = await api.load(bundle, params)
      const payload = await response.json()

      if (response.ok) {
        onLoad(payload, dispatch, getState, key)
      }
      else {
        if (typeof payload.message === 'string') {
          dispatch(setBundleError(bundle, payload.message, key))
        }
      }
    }
    catch (e) {
      console.error(e)
      dispatch(setBundleError(bundle, e.message, key))
    }

    // Finish dta loading.
    dispatch(updateBundle(bundle, {
      loading: false,
      loaded: true
    }, key))
  }
}

/**
 * Action to set data.
 *
 * @param {string} bundle
 *   Store bundle name.
 * @param {Object} data
 *   Data to set.
 * @param {any} key
 *   (optional) Data key inside bundle data.
 *
 * @return {Object}
 */
export function dataSet (bundle, data, key = null) {
  return updateBundle(bundle, { data }, key)
}

/**
 * Action to append data.
 *
 * @param {string} bundle
 *   Store bundle name.
 * @param {Object|Array} data
 *   Data to append.
 * @param {any} key
 *   (optional) Data key inside bundle data.
 *
 * @return {Object}
 */
export function dataMerge (bundle, data, key = null) {
  return {
    type: LOAD_ACTION_TYPES.DATA_MERGE,
    bundle,
    data,
    key
  }
}

/**
 * Helper to create actions for load Redux bundles.
 *
 * @param {string} bundle
 *   The store bundle.
 * @param {string} method
 *   (optional) The method to use to handle loaded data. One of "set" or "merge".
 *
 * @return {{load: Function}}
 */
export function actionsFactory (bundle, method = DATA_HANDLE_METHODS.SET) {

  /**
   * Handles data loads.
   *
   * @param {Object} payload
   * @param {Function} dispatch
   * @param {Function} getState
   * @param {any} key
   */
  function onLoad (payload, dispatch, getState, key) {
    const { data } = payload
    if (method === DATA_HANDLE_METHODS.SET) {
      dispatch(dataSet(bundle, data, key))
    }
    else if (method === DATA_HANDLE_METHODS.MERGE) {
      dispatch(dataMerge(bundle, data, key))
    }
  }

  /**
   * Action to load bundle data by its key.
   *
   * @param {any} key
   *   (optional) Item key.
   * @param {Object} params
   *   (optional) Additional request parameters.
   *
   * @return {function(...[*]=)}
   */
  function load (key = null, params = {}) {
    if (key === null) {
      return loadData(bundle, onLoad, params)
    }
    return loadData(bundle, onLoad, params, key)
  }

  /**
   * Action to reset bundle state.
   *
   * @return {{reset: Object, type: string, bundle: string, key: any}}
   */
  function reset (key = null, reset = {}) {
    return resetBundle(bundle, reset, key)
  }

  return {
    load,
    reset
  }
}
