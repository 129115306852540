import { LOAD_ACTION_TYPES, LOAD_INITIAL_STATE } from 'ps/constants'

import { baseReducer } from './baseReducer'

/**
 * General reducer for "load" Redux bundles.
 *
 * @param {Object} state
 *   Redux state.
 * @param {Object} action
 *   Action to handle.
 *
 * @return {Object}
 */
function reducer (state, action) {
  switch (action.type) {

    case LOAD_ACTION_TYPES.DATA_MERGE:
      if (action.key) {
        if (Array.isArray(action.data)) {
          return {
            ...state,
            [action.key]: {
              ...state[action.key],
              data: [
                ...(state[action.key].data || []),
                ...action.data
              ]
            }
          }
        }
        else {
          return {
            ...state,
            [action.key]: {
              ...state[action.key],
              data: {
                ...state[action.key].data,
                ...action.data
              }
            }
          }
        }
      }
      else {
        if (Array.isArray(action.data)) {
          return {
            ...state,
            data: [
              ...(state.data || []),
              ...action.data
            ]
          }
        }
        else {
          return {
            ...state,
            data: {
              ...state.data,
              ...action.data
            }
          }
        }
      }

    default:
      return baseReducer(state, action)
  }
}

/**
 * Helper to create reducers for "get/load" Redux bundles.
 *
 * @param {string} bundle
 *   Bundle name.
 * @param {Object} initialState
 *   (optional) Bundle initial state. Defaults to LOAD_INITIAL_STATE.
 *
 * @return {function(...[*]=)}
 */
export function reducerFactory (bundle, initialState = LOAD_INITIAL_STATE) {
  return function (state = initialState, action) {
    if (action.bundle === bundle) {
      return reducer(state, action)
    }
    return state
  }
}
