/**
 * @file
 * General constants.
 */

/**
 * The site name.
 *
 * @type {string}
 */
export const SITE_NAME = 'Пилигримы'

/**
 * Student reports names and titles.
 *
 * @type {{study: string}}
 */
export const STUDENT_REPORTS = {
  study: 'Мои знания за месяц'
}

/**
 * A list of month names.
 *
 * @type {string[]}
 */
export const MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
]
