import React from 'react'
import { Accordion } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowDown } from '@fortawesome/pro-duotone-svg-icons'

import { DataStatus } from 'ps/components'
import { BUNDLES } from 'ps/constants'
import { useBundle, useLoadTempEffect, useStudent } from 'ps/hooks'
import { formatDate } from 'ps/utils'

import { StudentBackLink } from './StudentBackLink'

/**
 * Renders student's feedback.
 *
 * @return {JSX.Element}
 * @constructor
 */
export function StudentFeedback () {
  const { code } = useStudent()

  useLoadTempEffect(BUNDLES.GET_STUDENT_FEEDBACK, null, { code })

  return (
    <div className="col">
      <div className="card">
        <div className="card-header fs-5">
          <StudentBackLink/>
          Мои отзывы
        </div>
        <div className="card-body">
          <DataStatus bundle={BUNDLES.GET_STUDENT_FEEDBACK}>
            <Content/>
          </DataStatus>
        </div>
      </div>
    </div>
  )
}

/**
 * Renders page content.
 *
 * @return {*}
 * @constructor
 */
function Content () {
  const { data } = useBundle(BUNDLES.GET_STUDENT_FEEDBACK)

  return (
    <Accordion>
      {data.map((item, index) => {
        const { id, timestamp, subject, body, documents } = item
        const date = new Date(timestamp)
        return (
          <Accordion.Item key={id} eventKey={index}>
            <Accordion.Header>
              {formatDate(date, 'do MMMM yyyy')}
              {!!subject && (
                <span className="badge bg-secondary ms-2">{subject['title']}</span>
              )}
            </Accordion.Header>
            <Accordion.Body>
              <div dangerouslySetInnerHTML={{ __html: body }}/>
              {documents.length > 0 && (
                <div className="mt-3">
                  {documents.map(doc => {
                    const { id, filename, description, url } = doc
                    const title = description || filename
                    return (
                      <div key={id}>
                        <FontAwesomeIcon icon={faFileArrowDown} fixedWidth={true} className="me-2"/>
                        <a href={url} target="_blank">{title}</a>
                      </div>
                    )
                  })}
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        )
      })}
    </Accordion>
  )
}
