import React from 'react'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'

/**
 * Navigation link that gets proper classes when it's active.
 *
 * @param props
 * @return {*}
 * @constructor
 */
export function NavLink (props) {
  let className = props.className || ''
  const path = useResolvedPath(props.to)
  if (useMatch(path.pathname)) {
    className += ' active'
  }
  if (useMatch(path.pathname + "/*")) {
    className += ' trailing'
  }
  return (
    <Link
      {...props}
      className={className}
    />
  )
}
