import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'

/**
 * Renders an arrow link that points to the student root page.
 *
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
export function StudentBackLink (props) {
  const { path, permanent } = props
  return (
    <Link to={path} className={'d-inline me-2' + (permanent ? '' : ' d-md-none')}>
      <FontAwesomeIcon icon={faArrowLeft} fixedWidth={true}/>
    </Link>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
StudentBackLink.propTypes = {
  path: PropTypes.string,
  permanent: PropTypes.bool
}

StudentBackLink.defaultProps = {
  path: '..',
}
