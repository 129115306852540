import React from 'react'
import PropTypes from 'prop-types'

/**
 * Common loading spinner.
 *
 * @param props
 * @return {null|*}
 * @constructor
 */
export function Loading (props) {
  const { className, loading, type } = props

  if (loading) {
    return (
      <div className={className}>
        <div
          className={'spinner-' + type}
          role="status"
        >
          <span className="sr-only">
            Загрузка...
          </span>
        </div>
      </div>
    )
  }

  return null
}

/**
 * Available loading styles.
 *
 * @type {{}}
 */
const TYPES = {
  BORDER: 'border',
  GROW: 'grow'
}

/**
 * Component properties.
 *
 * @type {{}}
 */
Loading.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.any
}

/**
 * Component props default values.
 *
 * @type {{}}
 */
Loading.defaultProps = {
  className: 'text-center',
  type: TYPES.BORDER
}

Loading.TYPES = TYPES
