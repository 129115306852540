import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { DataStatus, MonthDataLoader, NavLink } from 'ps/components'
import { BUNDLES } from 'ps/constants'
import { useBundle, useLoadTempEffect, useStudent } from 'ps/hooks'

import { StudentBackLink } from './StudentBackLink'

/**
 * Renders a student log page.
 *
 * @return {JSX.Element}
 * @constructor
 */
export function StudentLog () {
  const { code } = useStudent()

  useLoadTempEffect(BUNDLES.GET_STUDENT_LOG, null, { code })

  return (
    <div className="col">
      <div className="card">
        <div className="card-header fs-5">
          <StudentBackLink/>
          Моя хроника
        </div>
        <DataStatus
          bundle={BUNDLES.GET_STUDENT_LOG}
          append={true}
          loader={(
            <div className="card-body text-center">
              <MonthDataLoader bundle={BUNDLES.GET_STUDENT_LOG}/>
            </div>
          )}
        >
          <Content/>
        </DataStatus>
      </div>
    </div>
  )
}

/**
 * Renders a page content.
 *
 * @return {*}
 * @constructor
 */
function Content () {
  const { data } = useBundle(BUNDLES.GET_STUDENT_LOG)

  return (
    <div className="list-group list-group-flush">
      {data.map(day => (
        <LogDay key={day.date} data={day}/>
      ))}
      {data.length === 0 && (
        <div className="my-4 text-muted fs-4 text-center">
          Кажется, в этом разделе пока пусто
        </div>
      )}
    </div>
  )
}

/**
 * Renders a day section.
 *
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
function LogDay (props) {
  const { data } = props
  const { date_day, date_month, items } = data

  return (
    <div className="list-group-item py-3">
      <div className="hstack gap-3 align-items-start">
        <div className="event-date">
          <span className="event-date-month">{date_month}</span>
          <span className="event-date-day">{date_day}</span>
        </div>
        <div className="me-auto">
          {items.map((item, index) => (
            <Fragment key={item.id}>
              <LogItem item={item}/>
              {index < items.length - 1 && item.type !== 'visit' && (
                <div className="border-bottom-dashed my-3"/>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
LogDay.propTypes = {
  data: PropTypes.object.isRequired
}

/**
 * Renders a single log item.
 *
 * @param {Object} props
 * @return {JSX.Element|null}
 * @constructor
 */
export function LogItem (props) {
  const { item } = props
  const { type, activity } = item
  const { title } = activity

  switch (type) {
    case 'study': {
      const { score, note } = item
      const { course } = activity
      return (
        <Fragment>
          <div className="mb-2">
            Работа по
            теме <strong className="fst-italic">{title}</strong> (курс <CourseLink course={course}/>)
          </div>
          <div className="text-muted small">Знания: +{score}</div>
          {!!note && (
            <div className="mt-2 p-3 bg-light border-start">{note}</div>
          )}
        </Fragment>
      )
    }

    case 'test': {
      const { score, note } = item
      const { course } = activity
      return (
        <Fragment>
          <div className="mb-2">
            Пройден тест по
            теме <strong className="fst-italic">{title}</strong> (курс <CourseLink course={course}/>)
          </div>
          <div className="text-muted small">
            Результат: {score} {activity['score_max'] ? ' из ' + activity['score_max'] : ''}
          </div>
          {!!note && (
            <div className="mt-2 p-3 bg-light border-start">{note}</div>
          )}
        </Fragment>
      )
    }

    case 'lesson': {
      const { lesson_topic, note } = item
      const { subject, class_type } = activity
      const label = class_type === 'workshop' ? 'Практикум' : 'Урок'
      return (
        <Fragment>
          <div>
            {label} <strong className="fst-italic">{title}</strong> {!!subject && subject.title !== title && (
            '(' + subject.title + ')'
          )}
          </div>
          {!!lesson_topic && (
            <div className="text-muted small mt-2">
              Тема: &laquo;{lesson_topic['title']}&raquo;
            </div>
          )}
          {!!note && (
            <div className="mt-2 p-3 bg-light border-start">{note}</div>
          )}
        </Fragment>
      )
    }

    case 'exam': {
      const { score, note } = item
      const { subject } = activity
      return (
        <Fragment>
          <div className="mb-2">
            <strong className="fst-italic">{title}</strong> {subject ? '(' + subject.title + ')' : ''}
          </div>
          {typeof score !== 'undefined' && (
            <div className="text-muted small">Результат: {score} из {activity['score_max']}</div>
          )}
          {!!note && (
            <div className="mt-2 p-3 bg-light border-start">{note}</div>
          )}
        </Fragment>
      )
    }

    case 'teamwork': {
      const { skills, note } = item
      const { subject } = activity
      return (
        <Fragment>
          <div className="mb-2">
            Командная работа <strong className="fst-italic">{title}</strong> {subject ? '(' + subject.title + ')' : ''}
          </div>
          {skills && skills.length > 0 && (
            <div className="text-muted small">
              Прокачаны навыки:
              <ul style={{ columns: '250px 3' }}>
                {skills.map(skill => (
                  <li key={skill.id}>{skill.title}</li>
                ))}
              </ul>
            </div>
          )}
          {!!note && (
            <div className="mt-2 p-3 bg-light border-start">{note}</div>
          )}
        </Fragment>
      )
    }

    case 'event': {
      return (
        <div>
          Событие <strong className="fst-italic">{title}</strong>
        </div>
      )
    }

    case 'visit': {
      const { delay } = item
      return (
        <div className="alert alert-danger" role="alert">
          Время опоздания: {delay} мин
        </div>
      )
    }

    default:
      return null
  }
}

/**
 * Component properties.
 *
 * @type {{}}
 */
LogItem.propTypes = {
  item: PropTypes.object.isRequired
}

/**
 * Renders a link to a course.
 *
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
function CourseLink (props) {
  const { course } = props
  const { id, title } = course
  return (
    <NavLink to={'../courses?course=' + id}>
      {title}
    </NavLink>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
CourseLink.propTypes = {
  course: PropTypes.object.isRequired
}
