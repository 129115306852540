import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons'

/**
 * Common error alert.
 *
 * @param props
 * @return {null|*}
 * @constructor
 */
export function Error (props) {
  const { error, className, children } = props

  if (error) {
    return (
      <div className={'alert alert-danger' + (className ? ' ' + className : '')} role="alert">
        <div className="d-flex align-items-center">
          <div className="me-3">
            <FontAwesomeIcon icon={faExclamationTriangle} size="2x"/>
          </div>
          <div>
            {typeof error === 'boolean' ? children : (
              Array.isArray(error) ?
                (error.map(e => <div>{translateError(e)}</div>)) :
                translateError(error)
            )}
          </div>
        </div>
      </div>
    )
  }

  return null
}

/**
 * Component properties.
 *
 * @type {{}}
 */
Error.propTypes = {
  error: PropTypes.any,
  className: PropTypes.string
}

/**
 * Translates server errors into human-friendly messages.
 *
 * @param error
 *   The original error received from the server.
 *
 * @return {*}
 *   The translated error message.
 */
function translateError (error) {
  switch (error) {
    case 'Student not found':
      return (
        <Fragment>
          <h4 className="alert-heading">
            Профиль ученика не найден
          </h4>
          <div>
            Вероятно, ваша ссылка неверна или устарела. Обратитесь за помощью к своему тьютору
          </div>
        </Fragment>
      )

    default:
      return error
  }
}
