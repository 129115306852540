import React, { Fragment, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import Calendar from 'react-calendar'
import { Accordion } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons'

import { DataStatus } from 'ps/components'
import { BUNDLES } from 'ps/constants'
import { useBundle, useLoadPermEffect, useStudent } from 'ps/hooks'
import { formatDate, formatDay, formatMonth } from 'ps/utils'

import { StudentBackLink } from './StudentBackLink'
import { LogItem } from './StudentLog'

/**
 * Renders a student activity calendar.
 *
 * @return {JSX.Element}
 * @constructor
 */
export function StudentCalendar () {
  const { code } = useStudent()
  const [value, onChange] = useState(null)
  const [month, setMonth] = useState(formatMonth(new Date()))
  const { data, loading } = useBundle(BUNDLES.GET_STUDENT_CALENDAR, month)
  const hasData = !!data
  const day = value ? formatDay(value) : null

  useLoadPermEffect(BUNDLES.GET_STUDENT_CALENDAR, month, { code, month })

  /**
   * Returns class names for a calendar tile.
   * @type {function({date: Date, view: string}): (string|undefined)}
   */
  const tileClassName = useCallback(function ({ date, view }) {
    if (hasData && data['days'] && view === 'month') {
      const key = formatDay(date)
      const classes = ['rounded-3', 'border', 'border-white', 'position-relative']
      if (day && key === day) {
        classes.push('bg-primary')
      }
      else if (typeof data['days'][key] !== 'undefined') {
        const item = data['days'][key]
        if (item['log']) {
          const index = (item['log'] > 6 ? 6 : item['log']) + 1
          classes.push('bg-' + index + '00')
        }
      }
      return classes.join(' ')
    }
  }, [data, day])

  /**
   * Returns calendar tile content.
   * @type {function({date: Date, view: string}): (*|undefined)}
   */
  const tileContent = useCallback(function ({ date, view }) {
    if (hasData && data['days'] && view === 'month') {
      const key = formatDay(date)
      if (typeof data['days'][key] !== 'undefined') {
        const item = data['days'][key]
        return (
          <div
            className="position-absolute top-0 end-0"
            style={{
              marginRight: '2px',
              marginTop: '-2px'
            }}
          >
            {!!item['exam'] && (
              <FontAwesomeIcon
                icon={faCircleSmall}
                size="sm"
                className="text-danger"
              />
            )}
            {!!item['event'] && (
              <FontAwesomeIcon
                icon={faCircleSmall}
                size="sm"
                className="text-info"
              />
            )}
          </div>
        )
      }
    }
  }, [data])

  /**
   * Calendar start date change callback.
   * @type {function({activeStartDate: Date, view: string}): void}
   */
  const onActiveStartDateChange = useCallback(function ({ activeStartDate, view }) {
    if (view === 'month') {
      setMonth(formatMonth(activeStartDate))
    }
    onChange(null)
  }, [])

  return (
    <div className="col">
      <div className="card">
        <div className="card-header fs-5">
          <StudentBackLink/>
          Мой календарь
        </div>
        <div className="row g-0">
          <div className="col-12 col-lg-auto">
            <div className="card-body">
              <Calendar
                showNeighboringMonth={false}
                className="border-0 mx-auto ms-lg-0"
                value={value}
                onChange={onChange}
                tileClassName={tileClassName}
                tileContent={tileContent}
                onActiveStartDateChange={onActiveStartDateChange}
              />
              {/*<Loading loading={loading}/>*/}
              {hasData && (
                <MonthSummary data={data['summary']}/>
              )}
            </div>
          </div>
          <div className="col-12 col-lg border-start">
            <div className="card-body">
              {hasData && !!data['days'] && !!day && (
                data['days'][day] ? (
                  <CalendarDay date={value}/>
                ) : (
                  <Fragment>
                    <h4 className="mb-4">{formatDate(value, 'do MMMM yyyy')}</h4>
                    <div className="text-muted fs-4 text-center">
                      В этот день ничего не происходило. Совсем ничего
                    </div>
                  </Fragment>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * Renders a month summary.
 *
 * @return {*}
 * @constructor
 */
function MonthSummary (props) {
  const { data } = props
  const { visit, teamwork, lesson, delay_count, delay_sum } = data
  const delayCount = delay_count || 0
  const delaySum = delay_sum || 0
  return (
    <Accordion className="mt-3">
      <Accordion.Item eventKey={0}>
        <Accordion.Header>
          Сводка за месяц
        </Accordion.Header>
        <Accordion.Body>
          <div className="mb-2 pb-2 border-bottom-dashed">
            Учебных дней: <span className="float-end">{visit || 0}</span>
          </div>
          <div className="mb-2 pb-2 border-bottom-dashed">
            Командных работ: <span className="float-end">{teamwork || 0}</span>
          </div>
          <div className="mb-2 pb-2 border-bottom-dashed">
            Уроков и практикУМов: <span className="float-end">{lesson || 0}</span>
          </div>
          <div className={'mb-2 pb-2 border-bottom-dashed' + (delayCount > 5 ? ' text-danger' : (delayCount > 1 ? ' text-warning' : ''))}>
            Опозданий: <span className="float-end">{delayCount}</span>
          </div>
          <div className={delaySum > 50 ? ' text-danger' : (delayCount > 10 ? ' text-warning' : '')}>
            Время опозданий: <span className="float-end">{delaySum} мин</span>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
MonthSummary.propTypes = {
  data: PropTypes.object.isRequired
}

/**
 * Renders a single calendar day.
 *
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
function CalendarDay (props) {
  const { code } = useStudent()
  const { date } = props
  const day = formatDay(date)

  useLoadPermEffect(BUNDLES.GET_STUDENT_DAY, day, { code, date: day })

  return (
    <Fragment>
      <h4 className="mb-4">{formatDate(date, 'do MMMM yyyy')}</h4>
      <DataStatus bundle={BUNDLES.GET_STUDENT_DAY} bundleKey={day}>
        <DayContent date={day}/>
      </DataStatus>
    </Fragment>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
CalendarDay.propTypes = {
  date: PropTypes.object.isRequired
}

/**
 * Renders a calendar day content (when day data is already loaded).
 *
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
function DayContent (props) {
  const { date } = props
  const { data } = useBundle(BUNDLES.GET_STUDENT_DAY, date)
  const { empty, exam, event, log } = data

  if (empty) {
    return (
      <div className="text-muted fs-4 text-center">
        Просто ещё один прекрасный день в школе
      </div>
    )
  }

  return (
    <Fragment>
      {!!exam && exam.map(item => {
        const { id, title, body, subject } = item
        return (
          <div
            key={id}
            className="card card-body border border-2 border-danger shadow-none text-danger mb-3"
          >
            {!!subject && (
              <div className="small">
                {subject['title']}
              </div>
            )}
            <h5 className="card-title">{title}</h5>
            {!!body && (
              <div dangerouslySetInnerHTML={{ __html: body }}/>
            )}
          </div>
        )
      })}
      {!!event && event.map(item => {
        const { id, title, body } = item
        return (
          <div
            key={id}
            className="card card-body border border-2 border-info shadow-none text-info mb-3"
          >
            <h5 className="card-title">{title}</h5>
            {!!body && (
              <div dangerouslySetInnerHTML={{ __html: body }}/>
            )}
          </div>
        )
      })}
      {!!log && log.map((item, index) => (
        <Fragment key={item.id}>
          <LogItem item={item}/>
          {index < log.length - 1 && item.type !== 'visit' && (
            <div className="border-bottom-dashed my-3"/>
          )}
        </Fragment>
      ))}
    </Fragment>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
DayContent.propTypes = {
  date: PropTypes.string.isRequired
}
