import React from 'react'
import PropTypes from 'prop-types'

/**
 * Common inline spinner.
 *
 * @param props
 * @return {null|*}
 * @constructor
 */
export function Spinner (props) {
  const { className, show, type, small } = props

  if (show) {
    const classes = ['spinner-' + type]
    if (small) {
      classes.push('spinner-' + type + '-sm')
    }
    if (className) {
      classes.push(className)
    }

    return (
      <span
        className={classes.join(' ')}
        role="status"
        aria-hidden="true"
      >
        <span className="sr-only">
          Loading...
        </span>
      </span>
    )
  }

  return null
}

/**
 * Available spinner styles.
 *
 * @type {{}}
 */
const TYPES = {
  BORDER: 'border',
  GROW: 'grow'
}

/**
 * Component properties.
 *
 * @type {{}}
 */
Spinner.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  small: PropTypes.bool,
  show: PropTypes.bool.isRequired
}

/**
 * Component props default values.
 *
 * @type {{}}
 */
Spinner.defaultProps = {
  className: '',
  type: TYPES.BORDER,
  small: true
}

Spinner.TYPES = TYPES
