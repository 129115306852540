import React from 'react'
import { faGraduationCap } from '@fortawesome/pro-duotone-svg-icons'

import { NavLink } from 'ps/components'
import { STUDENT_REPORTS } from 'ps/constants'

import { StudentBackLink } from './StudentBackLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * Renders a student reports page.
 *
 * @return {JSX.Element}
 * @constructor
 */
export function StudentReports () {
  return (
    <div className="col">
      <div className="card">
        <div className="card-header fs-5">
          <StudentBackLink/>
          Мои отчёты
        </div>
        <ul className="list-group list-group-flush">
          {Object.keys(STUDENT_REPORTS).map(key => (
            <NavLink
              key={key}
              to={key}
              className="list-group-item list-group-item-action py-3"
            >
              <FontAwesomeIcon
                icon={getIconForReport(key)}
                fixedWidth={true}
                className="me-2"
              />
              {STUDENT_REPORTS[key]}
            </NavLink>
          ))}
        </ul>
      </div>
    </div>
  )
}

/**
 * Returns an icon for a report.
 *
 * @param {string} name
 *   The report name.
 *
 * @return
 *   The icon definition.
 */
function getIconForReport (name) {
  switch (name) {
    case 'study':
      return faGraduationCap
    default:
      return null
  }
}
