import { FORM_BUNDLES, LOAD_BUNDLES } from 'ps/constants'

import { actionsFactory as loadActionsFactory} from './loadActions'
import { actionsFactory as formActionsFactory} from './formActions'

/**
 * Redux actions.
 */
export const actions = {}

// Add simple load actions.
Object.keys(LOAD_BUNDLES).forEach(bundle => {
  actions[bundle] = loadActionsFactory(bundle, LOAD_BUNDLES[bundle])
})

// Add simple form actions.
FORM_BUNDLES.forEach(bundle => {
  actions[bundle] = formActionsFactory(bundle)
})
