import { URLS } from 'ps/constants'
import { getCsrfToken } from 'ps/utils'

/**
 * Prepares and returns URL for a bundle.
 *
 * @param {string} bundle
 *   Redux bundle name to send request to.
 * @param {Object} params
 *   (optional) Request parameters.
 * @param {String} method
 *   (optional) Request method.
 */
function getUrl (bundle, params = {}, method = null) {
  let url
  if (typeof URLS[bundle + '__' + method] === 'string') {
    url = URLS[bundle + '__' + method]
  }
  else {
    url = URLS[bundle]
  }

  url += '?_format=json'

  Object.keys(params).forEach(key => {
    const name = ':' + key
    const value = params[key]

    // Add parameter to the query if it's not presented as a named parameter in
    // the URL.
    if (url.indexOf(name) === -1) {
      url += '&' + key + '=' + value
    }
    // Otherwise replace corresponding placeholder with actual value.
    else {
      url = url.replace(name, value)
    }
  })

  return url
}

/**
 * General remote data getter (loader).
 *
 * @param {string} bundle
 *   Redux bundle name to load data for.
 * @param {Object} params
 *   (optional) Load parameters.
 *
 * @return {Promise<Response>}
 */
async function load (bundle, params = {}) {
  const url = getUrl(bundle, params)
  return await fetch(url, {
    credentials: 'include'
  })
}

/**
 * Submits form values to the server.
 *
 * @param {string} bundle
 *   Bundle name of the form.
 * @param {Object} values
 *   Submitted values.
 * @param {Object} params
 *   (optional) Submit URL params.
 * @param {string} method
 *   (optional) Request methods. Default to "POST".
 *
 * @return {Promise<Response>}
 */
async function submit (bundle, values, params = {}, method = 'POST') {
  // Some pseudo-forms require GET method.
  if (method === 'GET') {
    return load(bundle, values)
  }

  const url = getUrl(bundle, params, method)
  return await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCsrfToken()
    },
    body: JSON.stringify(values),
    credentials: 'include',
    method
  })
}

/**
 * Uploads a file to the server.
 *
 * @param {string} entity_type
 *   Entity type ID.
 * @param {string} bundle
 *   Entity bundle or entity ID.
 * @param {string} field_name
 *   Name of the file field.
 * @param {Object} file
 *   File object as returned by browser File API.
 * @param {Object} signal
 *   (optional) Signal to abort fetch.
 *
 * @return {Promise<Response>}
 */
async function upload (entity_type, bundle, field_name, file, signal = null) {
  const url = getUrl('FILE_UPLOAD', { entity_type, bundle, field_name })
  return fetch(url, {
    headers: {
      'Content-Type': 'application/octet-stream',
      'Content-Disposition': 'file; filename="' + file.name + '"',
      'X-CSRF-Token': getCsrfToken()
    },
    body: file,
    credentials: 'include',
    method: 'POST',
    signal
  })
}

export default {
  load,
  submit,
  upload
}
