import { FORM_BUNDLES, LOAD_BUNDLES } from 'ps/constants'

import { reducerFactory as loadReducerFactory } from './loadReducer'
import { reducerFactory as formReducerFactory } from './formReducer'

/**
 * Redux reducers.
 */
const reducers = {}

// Add simple load actions.
Object.keys(LOAD_BUNDLES).forEach(bundle => {
  reducers[bundle] = loadReducerFactory(bundle)
})

// Add simple form actions.
FORM_BUNDLES.forEach(bundle => {
  reducers[bundle] = formReducerFactory(bundle)
})

export default reducers
