import { applyMiddleware, createStore, combineReducers } from 'redux'
import { withExtraArgument } from 'redux-thunk'

import api from 'ps/api'

import reducers from './reducers'

/**
 * Initializes and returns Redux store.
 *
 * @return {*}
 *   Redux store.
 */
export default function initStore() {
  return createStore(
    combineReducers(reducers),
    applyMiddleware(withExtraArgument(api))
  )
}

export * from './actions'
export * from './defaults'
export * from './selectors'
