import React, { Fragment, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Accordion } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import Scroll from 'react-scroll'

import { DataStatus, NavLink } from 'ps/components'
import { BUNDLES } from 'ps/constants'
import { useBundle, useLoadPermEffect, useStudent } from 'ps/hooks'

import { StudentBackLink } from './StudentBackLink'
import { parseQuery } from 'ps/utils'

/**
 * Renders student's courses.
 *
 * @return {JSX.Element}
 * @constructor
 */
export function StudentCourses () {
  const { code } = useStudent()

  useLoadPermEffect(BUNDLES.GET_STUDENT_COURSES, null, { code })

  return (
    <div className="col">
      <div className="card">
        <div className="card-header fs-5">
          <StudentBackLink/>
          Мои знания
        </div>
        <div className="card-body">
          <DataStatus bundle={BUNDLES.GET_STUDENT_COURSES}>
            <Content/>
          </DataStatus>
        </div>
      </div>
    </div>
  )
}

/**
 * Renders a page content.
 *
 * @return {*}
 * @constructor
 */
function Content () {
  const { data } = useBundle(BUNDLES.GET_STUDENT_COURSES)

  const defaultKey = useMemo(function () {
    let key = null
    const query = parseQuery()
    if (query['course']) {
      data.forEach(function (item) {
        // noinspection EqualityComparisonWithCoercionJS
        if (item.id == query['course']) {
          key = item.id
        }
      })
    }
    return key
  }, [])

  // Courses grouped by subjects.
  const groups = useMemo(function () {
    const result = []
    const groups = {}

    data.forEach(function (course) {
      const { subject } = course
      if (subject) {
        const { id } = subject
        if (!groups[id]) {
          groups[id] = {...subject, courses: []}
          result.push(groups[id])
        }
        groups[id]['courses'].push(course)
      }
    })

    return result
  }, [data])

  // Scroll to the automatically open course, if any.
  useEffect(function () {
    const query = parseQuery()
    if (query['course']) {
      setTimeout(function () {
        Scroll.scroller.scrollTo('course-' + query['course'])
      }, 300)
    }
  }, [])

  return (
    <div className="d-grid gap-3">
      {groups.map(group => {
        const { id, title, courses } = group
        return (
          <div key={id}>
            <h4 className="fw-normal">{title}</h4>
            <Accordion defaultActiveKey={defaultKey}>
              {courses.map(item => {
                const { id, title, grade, subject } = item
                return (
                  <Accordion.Item key={id} name={'course-' + id} eventKey={id}>
                    <Accordion.Header>
                      <div>
                        <div className="mb-1">{title}</div>
                        <small className="opacity-50">
                          {!!grade && grade.title + '.'} {!!subject && subject.title}
                        </small>
                      </div>
                    </Accordion.Header>
                    <AccordionBody open={defaultKey === id} course={item}/>
                  </Accordion.Item>
                )
              })}
            </Accordion>
          </div>
        )
      })}
    </div>
  )
}

/**
 * Renders a single course data in an accordion item.
 *
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
function AccordionBody (props) {
  const { open, course } = props
  const { id } = course
  const [show, setShow] = useState(open)

  return (
    <Accordion.Collapse eventKey={id} onEntered={() => setShow(true)}>
      <div>
        {show && (
          <Course course={course}/>
        )}
      </div>
    </Accordion.Collapse>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
AccordionBody.propTypes = {
  open: PropTypes.bool.isRequired,
  course: PropTypes.object.isRequired
}

/**
 * Renders a single course content.
 *
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
function Course (props) {
  const { code } = useStudent()
  const { course } = props
  const { id } = course

  useLoadPermEffect(BUNDLES.GET_STUDENT_COURSE, id, { code, id })

  return (
    <DataStatus bundle={BUNDLES.GET_STUDENT_COURSE} bundleKey={id}>
      <ul className="list-group list-group-flush">
        <CourseTopics id={id}/>
      </ul>
    </DataStatus>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
Course.propTypes = {
  course: PropTypes.object.isRequired
}

/**
 * Renders a single course topics.
 *
 * @param {Object} props
 * @return {*}
 * @constructor
 */
function CourseTopics (props) {
  const { id } = props
  const { data } = useBundle(BUNDLES.GET_STUDENT_COURSE, id)

  return data.map(item => {
    const { exposed, topic, summary } = item
    const { id, title, score, score_max } = topic
    const { study_score, test_score, test_count } = summary || {}

    const studyText = (study_score || 0) + '/' + score
    const studyPct = Math.min(100, Math.round(100 * (study_score || 0) / score))
    const testPct = test_count === 0 ? 0 : (
      score_max ? Math.round(100 * test_score / test_count / score_max) : 100
    )

    let barClasses = ['progress-bar', 'text-nowrap', 'overflow-visible']
    barClasses.push('bg-' + (studyPct < 33 ? 'danger' : (studyPct < 66 ? 'warning' : 'success')))

    return (
      <li key={id} className="list-group-item">
        {exposed ? (
          <NavLink to={'topic/' + id} className="text-decoration-none">
            {title}
          </NavLink>
        ) : title}
        <div className="progress mt-3 mb-2">
          <div
            className={barClasses.join(' ')}
            role="progressbar"
            aria-valuenow={studyPct}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{
              width: studyPct + '%',
              minWidth: '2.5em'
            }}
          >
            <span className="mx-1">{studyText}</span>
          </div>
        </div>
        {(!!score_max || !!test_count) && (
          <div className="alert alert-info small px-2 py-2 mt-3 mb-2">
            {!!score_max && !test_count && (
              'Тест по теме ещё не пройден'
            )}
            {test_count > 0 && (
              <Fragment>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  fixedWidth={true}
                  className={'me-1 text-' + (testPct < 33 ? 'danger' : (testPct < 66 ? 'warning' : 'success'))}
                />
                Тест по теме: <strong>{testPct}%</strong>
              </Fragment>
            )}
          </div>
        )}
      </li>
    )
  })
}

/**
 * Component properties.
 *
 * @type {{}}
 */
CourseTopics.propTypes = {
  id: PropTypes.number.isRequired
}
