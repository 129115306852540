import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFile,
  faFilePdf,
  faHourglass
} from '@fortawesome/pro-duotone-svg-icons'

import { DataStatus, NoData } from 'ps/components'
import { BUNDLES } from 'ps/constants'
import { useBundle, useLoadPermEffect, useStudent } from 'ps/hooks'

import { StudentBackLink } from './StudentBackLink'

/**
 * Renders student's library page.
 *
 * @return {JSX.Element}
 * @constructor
 */
export function StudentLibrary () {
  const { code } = useStudent()

  useLoadPermEffect(BUNDLES.GET_STUDENT_LIBRARY, null, { code })

  return (
    <div className="col">
      <div className="card">
        <div className="card-header fs-5">
          <StudentBackLink/>
          Моя библиотека
        </div>
        <div className="card-body">
          <DataStatus bundle={BUNDLES.GET_STUDENT_LIBRARY}>
            <Content/>
          </DataStatus>
        </div>
      </div>
    </div>
  )
}

/**
 * Renders a page content.
 *
 * @return {*}
 * @constructor
 */
function Content () {
  const { data } = useBundle(BUNDLES.GET_STUDENT_LIBRARY)
  const { files } = data

  if (!files || !files.length) {
    return (
      <NoData
        icon={faHourglass}
        iconColor="info"
        message="Здесь пока пусто"
      />
    )
  }

  return files.map(file => {
    const { id, url, name, size, type } = file
    let icon = faFile
    if (type === 'application/pdf') {
      icon = faFilePdf
    }
    return (
      <div key={id} className="mb-2">
        <FontAwesomeIcon
          icon={icon}
          fixedWidth={true}
          className="me-1 text-secondary"
        />
        <a href={url} type={type} className="text-decoration-none">{name}</a> ({size})
      </div>
    )
  })
}
