import React, { Suspense } from 'react'

import { Loading } from './Loading'

export * from './DataStatus'
export * from './Dropdown'
export * from './Error'
export * from './Info'
export * from './Input'
export * from './Loading'
export * from './MonthDataLoader'
export * from './Navbar'
export * from './NavLink'
export * from './NoData'
export * from './Spinner'
export * from './Success'

// noinspection JSCheckFunctionSignatures
const LaTexAsync = React.lazy(() => import('./LaTex'))
export function LaTex (props) {
  return (
    <Suspense fallback={<Loading/>}>
      <LaTexAsync {...props}/>
    </Suspense>
  )
}

// noinspection JSCheckFunctionSignatures
const SurveyJsAsync = React.lazy(() => import('./SurveyJs'))
export function SurveyJs (props) {
  return (
    <Suspense fallback={<Loading/>}>
      <SurveyJsAsync {...props}/>
    </Suspense>
  )
}
