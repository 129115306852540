import { BUNDLES } from 'ps/constants'

/**
 * Redux selectors.
 */
export const selectors = {}

Object.values(BUNDLES).forEach(bundle => {
  selectors[bundle] = state => state[bundle]
})
