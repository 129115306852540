import React, { Fragment, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { sub } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowDown } from '@fortawesome/pro-light-svg-icons'

import { Spinner } from 'ps/components'
import { useBundle, useStudent } from 'ps/hooks'
import { actions } from 'ps/store'
import { formatDate, formatMonth } from 'ps/utils'

/**
 * Renders a button that loads data for the next month on click.
 *
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
export function MonthDataLoader (props) {
  const dispatch = useDispatch()
  const { code } = useStudent()

  const { bundle, bundleKey } = props
  const { loading } = useBundle(bundle, bundleKey)
  const [date, setDate] = useState()

  /**
   * Button click callback.
   *
   * @type {(function(*): void)|*}
   */
  const onClick = useCallback(function (e) {
    if (date) {
      // Load data for the current date's month.
      dispatch(actions[bundle].load(bundleKey, { code, month: formatMonth(date) }))
      // Subtract one month from the current date.
      setDate(sub(date, { months: 1 }))
    }
    e.preventDefault()
  }, [bundle, bundleKey, code, date])

  // Set/reset initial date.
  useEffect(function () {
    setDate(sub(new Date(), { months: 2 }))
  }, [bundle, bundleKey])

  if (!date) {
    return null
  }

  return (
    <button
      className="btn btn-outline-primary text-capitalize"
      onClick={onClick}
      disabled={loading}
    >
      {loading ? (
        <Fragment>
          <Spinner show={loading}/> Загрузка...
        </Fragment>
      ) : (
        <Fragment>
          <FontAwesomeIcon icon={faCloudArrowDown}/> Загрузить {formatDate(date, 'LLLL')}
        </Fragment>
      )}
    </button>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
MonthDataLoader.propTypes = {
  bundle: PropTypes.string.isRequired,
  bundleKey: PropTypes.any
}

/**
 * Component props default values.
 *
 * @type {{}}
 */
MonthDataLoader.defaultProps = {
  bundleKey: null
}
