import React from 'react'

import { useBodyClasses, usePageTitle } from 'ps/hooks'

import pageError_png from 'url:media/page-error.png'

/**
 * Renders a "Not Found" page.
 *
 * @param {Object} props
 * @return {*}
 * @constructor
 */
export function NotFound (props) {
  // Set page title.
  usePageTitle('Страница не найдена')

  // Set body classes.
  useBodyClasses('page-error')

  return (
    <main className="main">
      <div className="container">
        <div className="row py-4 py-md-6">
          <div className="col-12 col-md-7 d-flex align-items-center">
            <img src={pageError_png} alt="Page not found" className="img-fluid"/>
          </div>
          <div className="col-12 col-md-5 d-flex align-items-center">
            <div className="mx-auto text-center my-6 my-md-0">
              <h1 className="fs-10rem lh-1">
                404
              </h1>
              <div className="fs-1">
                Страница не найдена
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
