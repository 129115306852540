import React, { Fragment, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { DataStatus, Error, Input, LaTex, NavLink, Spinner, SurveyJs } from 'ps/components'
import { BUNDLES } from 'ps/constants'
import { useBundle, useFormChangeCallback, useLoadTempEffect, useStudent } from 'ps/hooks'
import { actions } from 'ps/store'
import { setFormResult, setFormValues, submitForm } from 'ps/store/formActions'
import { dataMerge } from 'ps/store/loadActions'

import { StudentBackLink } from './StudentBackLink'

/**
 * Renders single student's task.
 *
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
export function StudentTask (props) {
  const { code } = useStudent()
  const { id } = useParams()

  useLoadTempEffect(BUNDLES.GET_STUDENT_TASK, null, { code, id })

  return (
    <div className="col">
      <DataStatus bundle={BUNDLES.GET_STUDENT_TASK}>
        <Content/>
      </DataStatus>
    </div>
  )
}

/**
 * Renders page content.
 *
 * @return {*}
 * @constructor
 */
function Content () {
  return (
    <Fragment>
      <Body/>
      <Form/>
    </Fragment>
  )
}

/**
 * Renders task body.
 *
 * @return {*}
 * @constructor
 */
function Body () {
  const { data } = useBundle(BUNDLES.GET_STUDENT_TASK)
  const { body, title, progress, is_test } = data
  const hasBody = !is_test || !!body

  return (
    <div className="card mb-4">
      <div className={'card-header fs-5' + (!hasBody ? ' rounded-bottom' : '')}>
        <StudentBackLink permanent={true}/>
        {title}
      </div>
      {hasBody && (
        <div className="card-body">
          <div className="student-task">
            {!is_test && (
              <div className="progress mb-4">
                <div
                  className="progress-bar bg-info"
                  role="progressbar"
                  aria-valuenow={progress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{
                    width: progress + '%',
                    minWidth: '2.5em'
                  }}
                >
                  <span className="mx-1">{progress}%</span>
                </div>
              </div>
            )}
            <LaTex content={body} className="blockquote mb-2"/>
          </div>
        </div>
      )}
    </div>
  )
}

/**
 * Renders task form.
 *
 * @return {*}
 * @constructor
 */
function Form () {
  const { data } = useBundle(BUNDLES.GET_STUDENT_TASK)
  const { id } = data
  const { result, error, submitting } = useBundle(BUNDLES.FORM_STUDENT_TASK, id)

  if (submitting) {
    return (
      <button className="btn btn-primary" type="button" disabled>
        <Spinner show={true}/> Проверка...
      </button>
    )
  }

  if (result) {
    return <FormResult/>
  }
  else {
    return (
      <Fragment>
        <Error error={error}/>
        <Survey/>
      </Fragment>
    )
  }
}

/**
 * Renders task form result.
 *
 * @return {*}
 * @constructor
 */
function FormResult () {
  const dispatch = useDispatch()

  const { data } = useBundle(BUNDLES.GET_STUDENT_TASK)
  const { id } = data
  const { code } = useStudent()
  const { result } = useBundle(BUNDLES.FORM_STUDENT_TASK, id)

  /**
   * Task reload handler.
   *
   * @param {Object} e
   *   Click event.
   */
  const onReload = useCallback(function (e) {
    dispatch(actions[BUNDLES.GET_STUDENT_TASK].reset())
    dispatch(actions[BUNDLES.FORM_STUDENT_TASK].reset())
    dispatch(actions[BUNDLES.GET_STUDENT_TASK].load(null, { code, id }))
    e.preventDefault()
  }, [code, id])

  /**
   * Task finish handler.
   */
  const onFinish = useCallback(function () {
    dispatch(actions[BUNDLES.GET_STUDENT_SUMMARY].load(null, { code }))
  }, [code])

  return (
    <Fragment>
      {!!result['feedback'] && (
        <LaTex content={result['feedback']} className="card card-body mb-4"/>
      )}
      {result['progress'] < 100 ? (
        <button
          type="submit"
          className="btn btn-primary"
          onClick={onReload}
        >
          Продолжить
        </button>
      ) : (
        <NavLink to="../" onClick={onFinish} className="btn btn-primary">
          Завершить
        </NavLink>
      )}
    </Fragment>
  )
}

/**
 * Renders task survey.
 *
 * @return {*}
 * @constructor
 */
function Survey () {
  const dispatch = useDispatch()

  const { code } = useStudent()
  const { data } = useBundle(BUNDLES.GET_STUDENT_TASK)
  const { id, survey } = data
  const { values } = useBundle(BUNDLES.FORM_STUDENT_TASK, id)

  /**
   * Form submit handler.
   *
   * @param {Object} sender
   *   The survey sender.
   */
  const onSubmit = useCallback(function (sender) {
    dispatch(setFormValues(BUNDLES.FORM_STUDENT_TASK, sender.data, id))
    dispatch(submitForm(BUNDLES.FORM_STUDENT_TASK, sender.data, onFormSubmitted, { code, id }, id))
  }, [code, id])

  return (
    <SurveyJs
      fields={survey}
      onSubmit={onSubmit}
      defaultValues={values}
    />
  )
}

/**
 * Renders page content.
 *
 * @return {*}
 * @constructor
 */
function OldContent () {
  const { data } = useBundle(BUNDLES.GET_STUDENT_TASK)
  const { body, fields, progress } = data

  return (
    <div className="student-task">
      <div className="progress mb-4">
        <div
          className="progress-bar bg-info"
          role="progressbar"
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
          style={{
            width: progress + '%',
            minWidth: '2.5em'
          }}
        >
          <span className="mx-1">{progress}%</span>
        </div>
      </div>
      <LaTex content={body} className="blockquote my-4"/>
      {!!fields && <OldForm/>}
    </div>
  )
}

/**
 * Renders task form.
 *
 * @return {*}
 * @constructor
 */
function OldForm () {
  const dispatch = useDispatch()

  const { code } = useStudent()
  const { data } = useBundle(BUNDLES.GET_STUDENT_TASK)
  const { id, fields } = data
  const { error, errors, values, submitting, result } = useBundle(BUNDLES.FORM_STUDENT_TASK, id)

  /**
   * Form input change handler.
   *
   * @type {(function(*): void)|*}
   */
  const onChange = useFormChangeCallback(BUNDLES.FORM_STUDENT_TASK, id)

  /**
   * Form submit handler.
   *
   * @param {Object} e
   *   Form submit event.
   */
  const onSubmit = function (e) {
    dispatch(submitForm(BUNDLES.FORM_STUDENT_TASK, values, onFormSubmitted, { code, id }, id))
    e.preventDefault()
  }

  /**
   * Task reload handler.
   *
   * @param {Object} e
   *   Click event.
   */
  const onReload = function (e) {
    dispatch(actions[BUNDLES.GET_STUDENT_TASK].reset())
    dispatch(actions[BUNDLES.FORM_STUDENT_TASK].reset())
    dispatch(actions[BUNDLES.GET_STUDENT_TASK].load(null, { code, id }))
    e.preventDefault()
  }

  /**
   * Task finish handler.
   */
  const onFinish = function () {
    dispatch(actions[BUNDLES.GET_STUDENT_SUMMARY].load(null, { code }))
  }

  return (
    <form onSubmit={onSubmit} className="mt-4">
      {Object.keys(fields).map(name => {
        const config = fields[name]
        return (
          <div key={name} className="mb-3">
            <Input
              name={name}
              value={values[name] || ''}
              onChange={onChange}
              config={config}
            />
            <Error error={errors[name]}/>
          </div>
        )
      })}
      <Error error={error}/>
      {result ? (
        <Fragment>
          {!!result['feedback'] && (
            <LaTex content={result['feedback']} className="alert alert-light border-1 border"/>
          )}
          {result['progress'] < 100 ? (
            <button
              type="submit"
              className="btn btn-primary"
              onClick={onReload}
            >
              Продолжить
            </button>
          ) : (
            <NavLink to="../" onClick={onFinish} className="btn btn-primary">
              Завершить
            </NavLink>
          )}
        </Fragment>
      ) : (
        <button
          type="submit"
          className="btn btn-primary"
        >
          <Spinner show={submitting}/> Ответить
        </button>
      )}
    </form>
  )
}

/**
 * Form submitted handler.
 *
 * @param {Object} payload
 *   Form submission result.
 * @param {Function} dispatch
 *   Dispatch function.
 * @param {Function} getState
 *   Function to get Redux state.
 * @param {any} id
 *   The task ID.
 */
function onFormSubmitted (payload, dispatch, getState, id) {
  const progress = payload['progress'] || 0
  dispatch(setFormResult(BUNDLES.FORM_STUDENT_TASK, payload, id))
  dispatch(dataMerge(BUNDLES.GET_STUDENT_TASK, { progress }))
}
