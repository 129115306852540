/**
 * @file
 * Miscellaneous hooks.
 */

import { useCallback, useEffect, useState } from 'react'

import { getScrollTop, setPageTitle } from 'ps/utils'

/**
 * Hook to set page title.
 *
 * @param {String} title
 *   Page title to set.
 */
export function usePageTitle (title = null) {
  useEffect(function () {
    setPageTitle(title)
  }, [title])
}

/**
 * Hook to temporarily set body classes.
 *
 * @param classes
 *   The list of classes to set to body.
 */
export function useBodyClasses (...classes) {
  useEffect(function () {
    document.body.classList.add(...classes)
    return function () {
      document.body.classList.remove(...classes)
    }
  })
}

/**
 * Helper hook to toggle something on click.
 *
 * @param {boolean} initialState
 *   (optional) Initial open state. Default to FALSE.
 *
 * @return {[boolean, function]}
 */
export function useToggle (initialState = false) {
  const [isOpen, setIsOpen] = useState(initialState)
  const onToggle = useCallback(function (e) {
    setIsOpen(open => !open)
    if (e) {
      e.preventDefault()
    }
  }, [])
  return [isOpen, onToggle]
}

/**
 * Effect to get container's "scroll top" value.
 *
 * @param {HTMLElement} container
 *   (optional) Scrollable container to get scrollTop of. If not set,
 *   the document is used as a container.
 *
 * @return {number}
 *   The container's scrollTop value.
 */
export function useScrollTop (container = null) {
  const [scroll, setScroll] = useState()
  useEffect(function () {
    function onScroll () {
      const s = getScrollTop(container)
      setScroll(s)
    }
    const c = container || window
    c.addEventListener('scroll', onScroll)
    return function () {
      c.removeEventListener('scroll', onScroll)
    }
  }, [container])
  return scroll
}
