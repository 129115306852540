import { FORM_ACTION_TYPES } from 'ps/constants'

import { resetBundle, setBundleError, updateBundle } from './baseActions'

/**
 * Action to submit form.
 *
 * @param {string} bundle
 *   Store bundle name.
 * @param {Object} values
 *   Form values to be submitted.
 * @param {Function} onSubmit
 *   Function to be called on successful submit.
 * @param {Object} params
 *   (optional) Submit URL params.
 * @param {any} key
 *   (optional) Data key inside bundle data.
 * @param {string} method
 *   (optional) Request method. Default to "POST".
 *
 * @return {Function}
 */
export function submitForm (bundle, values, onSubmit, params = {}, key = null, method = 'POST') {
  return async function (dispatch, getState, api) {

    dispatch(updateBundle(bundle, {
      submitting: true,
      error: null,
      result: null
    }, key))

    try {
      const response = await api.submit(bundle, values, params, method)

      let payload
      try {
        payload = await response.json()
      }
      catch (e) {
        payload = {}
      }

      if (response.ok) {
        onSubmit(payload, dispatch, getState, key)
      }
      else {
        let errorSet = false
        if (payload.errors) {
          dispatch(setFormErrors(bundle, payload.errors, key))
          errorSet = true
        }
        if (payload.message) {
          dispatch(setBundleError(bundle, payload.message, key))
          errorSet = true
        }
        if (!errorSet) {
          dispatch(setBundleError(bundle, 'Unknown server error', key))
        }
      }
    }
    catch (e) {
      console.error(e)
      dispatch(setBundleError(bundle, e.message, key))
    }

    dispatch(updateBundle(bundle, {
      submitting: false
    }, key))
  }
}

/**
 * Action to update form values.
 *
 * @param {string} bundle
 *   Store bundle name.
 * @param {Object} values
 *   Hash of form values to set.
 * @param {any} key
 *   (optional) Data key inside bundle data.
 *
 * @return {Object}
 */
export function setFormValues (bundle, values, key = null) {
  return {
    type: FORM_ACTION_TYPES.SET_VALUES,
    bundle,
    values,
    key
  }
}

/**
 * Action to update form errors.
 *
 * @param {string} bundle
 *   Store bundle name.
 * @param {Object} errors
 *   Hash of form errors to set.
 * @param {any} key
 *   (optional) Data key inside bundle data.
 *
 * @return {Object}
 */
export function setFormErrors (bundle, errors, key = null) {
  return {
    type: FORM_ACTION_TYPES.SET_ERRORS,
    bundle,
    errors,
    key
  }
}

/**
 * Action to set form result.
 *
 * @param {string} bundle
 *   Store bundle name.
 * @param {any} result
 *   Form result to set.
 * @param {any} key
 *   (optional) Data key inside bundle data.
 *
 * @return {Object}
 */
export function setFormResult (bundle, result, key = null) {
  return updateBundle(bundle, { result }, key)
}

/**
 * Helper to create actions for form Redux bundles.
 *
 * @param {string} bundle
 *   Bundle name.
 *
 * @return {{submit: Function, setValues: Function, setErrors: Function, reset: Function}}
 */
export function actionsFactory (bundle) {

  /**
   * Handles form submits.
   *
   * @param {Object} payload
   * @param {Function} dispatch
   * @param {Function} getState
   * @param {*} id
   */
  function onSubmit (payload, dispatch, getState, id) {
    // Set form result.
    dispatch(setFormResult(bundle, payload['result'], id))
  }

  /**
   * Action to submit the form.
   *
   * @param {Object} values
   *   Form values to submit.
   * @param {any} key
   *   (optional) Data key inside bundle data.
   * @param {Object} params
   *   (optional) Request parameters.
   * @param {string} method
   *   (optional) Request method. Defaults to "POST".
   *
   * @return {function(...[*]=)}
   */
  function submit (values, key = null, params = {}, method = 'POST') {
    return submitForm(bundle, values, onSubmit, params, key, method)
  }

  /**
   * Action to set form values.
   *
   * @param {Object} values
   *   Form values to set.
   * @param {any} key
   *   (optional) Data key inside bundle data.
   *
   * @return {{values: Object, type: string, bundle: string, key: any}}
   */
  function setValues (values, key = null) {
    return setFormValues(bundle, values, key)
  }

  /**
   * Action to set form errors.
   *
   * @param {Object} errors
   *   Form errors to set.
   * @param {any} key
   *   (optional) Data key inside bundle data.
   *
   * @return {{errors: Object, type: string, bundle: string, key: any}}
   */
  function setErrors (errors, key = null) {
    return setFormErrors(bundle, errors, key)
  }

  /**
   * Action to reset form state.
   *
   * @param {any} key
   *   (optional) Data key inside bundle data.
   * @param {Object} reset
   *   (optional) State to set.
   *
   * @return {{reset: Object, type: string, bundle: string, key: any}}
   */
  function reset (key = null, reset = {}) {
    return resetBundle(bundle, reset, key)
  }

  return {
    submit,
    setValues,
    setErrors,
    reset
  }
}
