import { FORM_ACTION_TYPES, FORM_INITIAL_STATE } from 'ps/constants'

import { baseReducer } from './baseReducer'

/**
 * General reducer for form Redux bundles.
 *
 * @param {Object} state
 *   Redux state.
 * @param {Object} action
 *   Action to handle.
 * @param {Object} initialState
 *   The form initial state.
 *
 * @return {Object}
 */
function reducer (state, action, initialState) {
  switch (action.type) {

    case FORM_ACTION_TYPES.SET_VALUES:
      if (action.key !== null) {
        return {
          ...state,
          [action.key]: {
            ...(state[action.key] || initialState),
            values: {
              ...(state[action.key] && state[action.key].values || {}),
              ...action.values
            }
          }
        }
      }
      else {
        return {
          ...(state || initialState),
          values: {
            ...state.values,
            ...action.values
          }
        }
      }

    case FORM_ACTION_TYPES.SET_ERRORS:
      if (action.key !== null) {
        return {
          ...state,
          [action.key]: {
            ...(state[action.key] || initialState),
            errors: {
              ...(state[action.key] && state[action.key].errors || {}),
              ...action.errors
            }
          }
        }
      }
      else {
        return {
          ...(state || initialState),
          errors: {
            ...state.errors,
            ...action.errors
          }
        }
      }

    default:
      return baseReducer(state, action)
  }
}

/**
 * Helper to create reducers for form Redux bundles.
 *
 * @param {string} bundle
 *   Bundle name.
 * @param {Object} initialState
 *   (optional) Bundle initial state. Defaults to FORM_INITIAL_STATE.
 *
 * @return {function(...[*]=)}
 */
export function reducerFactory (bundle, initialState = FORM_INITIAL_STATE) {
  return function (state = {}, action) {
    if (action.bundle === bundle) {
      return reducer(state, action, initialState)
    }
    return state
  }
}
