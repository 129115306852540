import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React, { Fragment } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider as StoreProvider } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { Navbar } from 'ps/components'
import { Front, NotFound, Student } from 'ps/pages'
import initStore from 'ps/store'

const container = document.getElementById('app-root')
createRoot(container).render(
  <BrowserRouter>
    <StoreProvider store={initStore()}>
      <App/>
    </StoreProvider>
  </BrowserRouter>
)

/**
 * Renders the app.
 *
 * @return {*}
 * @constructor
 */
function App () {
  return (
    <Fragment>
      <Navbar/>
      <Routes>
        <Route path="/" element={<Front/>}/>
        <Route path="/student/:code/*" element={<Student/>}/>
        <Route path="*" element={<NotFound/>}/>
      </Routes>
    </Fragment>
  )
}
