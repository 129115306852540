/**
 * @file
 * Store-related hooks.
 */

import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { BUNDLES } from 'ps/constants'
import { actions, defaults, selectors } from 'ps/store'

/**
 * Helper hook to select store bundle.
 *
 * @param {String} bundle
 *   Bundle name.
 * @param {any} key
 *   (optional) The bundle key.
 *
 * @return *
 *   Bundle data.
 */
export function useBundle (bundle, key = null) {
  const data = useSelector(selectors[bundle])
  if (key === null) {
    return data
  }
  if (typeof data[key] === 'undefined') {
    return defaults[bundle]
  }
  return data[key]
}

/**
 * Shortcut hook to use current student's data.
 *
 * @return {*|{}}
 */
export function useStudent () {
  return useBundle(BUNDLES.GET_STUDENT)['data'] || {}
}

/**
 * Hook to reset bundle on exit.
 *
 * @param {string} bundle
 *   Name of the Redux bundle.
 * @param {Array} deps
 *   (optional) Reset dependencies.
 */
export function useReset (bundle, deps = []) {
  const dispatch = useDispatch()
  useEffect(function () {
    return function () {
      dispatch(actions[bundle].reset())
    }
  }, deps)
}

/**
 * Hook to get function to load data for Redux bundle.
 *
 * @param {string} bundle
 *   Name of the Redux bundle.
 * @param {any} key
 *   The key inside a bundle.
 * @param {Object} params
 *   (optional) Parameters to be passed to bundle's load() method.
 * @param {boolean} reset
 *   (optional) Whether to reset bundle before loading.
 *
 * @return {Function}
 *   Data loader function.
 */
export function useLoadCallback (bundle, key = null, params = {}, reset = false) {
  const dispatch = useDispatch()
  return function (e) {
    if (reset) {
      dispatch(actions[bundle].reset(key))
    }
    dispatch(actions[bundle].load(key, params))
    e.preventDefault()
  }
}

/**
 * Hook to load temporary data that will be cleared on unmount/key change.
 *
 * @param {string} bundle
 *   Name of the Redux bundle.
 * @param {any} key
 *   The key inside a bundle.
 * @param {Object} params
 *   (optional) Parameters to be passed to bundle's load() method.
 */
export function useLoadTempEffect (bundle, key = null, params = {}) {
  const dispatch = useDispatch()
  useEffect(function () {
    dispatch(actions[bundle].load(key, params))
    return function () {
      dispatch(actions[bundle].reset(key))
    }
  }, [key])
}

/**
 * Hook to load permanent data that isn't being changed during the app life.
 *
 * It checks if bundle data is empty and is not loading at the moment and
 * loads it only in this case.
 *
 * @param {string} bundle
 *   Name of the Redux bundle.
 * @param {any} key
 *   The key inside a bundle.
 * @param {Object} params
 *   (optional) Parameters to be passed to bundle's load() method.
 */
export function useLoadPermEffect (bundle, key = null, params = {}) {
  const dispatch = useDispatch()
  const { data, loading } = useBundle(bundle, key)
  useEffect(function () {
    if (!data && !loading) {
      dispatch(actions[bundle].load(key, params))
    }
  }, [key])
}

/**
 * Hook to get a callback to update form's field value.
 *
 * @param {string} bundle
 *   The bundle's name.
 * @param {*} key
 *   (optional) The form ID.
 *
 * @return {(function(*): void)|*}
 */
export function useFormChangeCallback (bundle, key = null) {
  const dispatch = useDispatch()
  return useCallback(function (e) {
    dispatch(actions[bundle].setValues({ [e.target.name]: e.target.value }, key))
  }, [bundle, key])
}

/**
 * Hook to get a callback to submit form's values.
 *
 * @param {Object} values
 *   The values to submit.
 * @param {string} bundle
 *   The bundle's name.
 * @param {*} key
 *   (optional) The form ID.
 * @param {Object} params
 *
 * @return {(function(*): void)|*}
 */
export function useFormSubmitCallback (values, bundle, key = null, params = {}) {
  const dispatch = useDispatch()
  return function (e) {
    dispatch(actions[bundle].submit(values, key, params))
    e.preventDefault()
  }
}
