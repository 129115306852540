import React, { Fragment } from 'react'

import { Error } from 'ps/components'

/**
 * Renders a front page.
 *
 * @param {Object} props
 * @return {*}
 * @constructor
 */
export function Front (props) {
  return (
    <main className="main">
      <div className="container">
        <div className="my-4 my-lg-6">
          <Error error={(
            <Fragment>
              <h4 className="alert-heading">
                На этот сайт можно зайти только по личной ссылке
              </h4>
              <div>
                Если вы не знаете, где взять личную ссылку, обратитесь к своему тьютору за помощью
              </div>
            </Fragment>
          )} className="shadow"/>

        </div>
      </div>
    </main>
  )
}
